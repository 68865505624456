import { render, staticRenderFns } from "./water-charts.vue?vue&type=template&id=5fd96eb1&scoped=true"
import script from "./water-charts.vue?vue&type=script&lang=js"
export * from "./water-charts.vue?vue&type=script&lang=js"
import style0 from "./water-charts.vue?vue&type=style&index=0&id=5fd96eb1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fd96eb1",
  null
  
)

export default component.exports
<template>
	<div class="flex-1" ref="waterChartRef" id="water-chart"></div>
</template>

<style scoped>
	#water-chart {
		width: 100%;
		height: 100%;
	}
</style>

<script>
	import * as echarts from 'echarts';

	// var _this
	export default {
		data() {
			return {}
		},
		created() {
			// _this = this
		},
		mounted() {
			this.loadWaterCharts()
		},
		methods: {
			async getSites() {
				let data = [];
				let url = 'screen/water/sites'
				let clientWidth = this.$refs.waterChartRef.clientWidth
				if(clientWidth < 1000) {
					url += '?isShowAll=false'
				}
				await this.$get(url).then(res => {
					data = (res.data || []);
				})
				return data;
			},
			async getSiteData(siteName) {
				var levels = []
				var times = []
				await this.$post('screen/riverlevel', {
					siteName: siteName,
					day: -7
					/*hour: 120*/
				}).then(res => {
					var data = res.data
					levels = data.map(it => it.level)
					times = data.map(it => it.time)
				})
				return {
					levels: levels,
					times: times
				}
			},
			async loadWaterCharts() {
				var myChart = echarts.init(document.getElementById('water-chart'))
				const sites = await this.getSites();
				let siteMap = {};
				let siteSelected = {};
				await Promise.all(sites.map(async(site, index) => {
					siteSelected[site.displayName] = index === 0;
					siteMap[site.displayName] = await this.getSiteData(site.realName);
				}))
				// var cuntan = await this.getSiteData('寸滩')
				// var lidu = await this.getSiteData('李渡')
				// var qingxichang = await this.getSiteData('涪陵：清溪场')
				// var guojiaba = await this.getSiteData('秭归（郭家坝）')
				var xShowDisplayName
				var xShowDisplayNum = 0
				var series = sites.map(site => {
					var data = siteMap[site.displayName].levels
					if(data.length > xShowDisplayNum) {
						xShowDisplayNum = data.length
						xShowDisplayName = site.displayName
					}
					return {
						name: site.displayName,
						type: 'line',
						data: data,
						legendHoverLink: false,
						label: {
							show: true,
							fontSize: 14,
							color: '#fff',
							rotate: 20
						}
					}
				})
				console.log(series)
				myChart.setOption({
					tooltip: {
						trigger: 'axis',
						textStyle: {
							fontSize: 14
						}
					},
					legend: {
						data: sites.map(it => it.displayName),
						textStyle: {
							fontSize: 12,
							color: '#00FFFF'
						},
						selected: siteSelected
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						axisLine: {
							show: true
						},
						splitArea: {
							// show: true,
							color: '#f00',
							lineStyle: {
								color: '#f00'
							},
						},
						axisLabel: {
							color: '#fff',
							textStyle: {
								fontSize: 14
							},
							padding: [0, 0, 0, 0],
							formatter: function(value) {
								return value.substring(0, 3) + "\n" + value.substring(3)
							}
						},
						splitLine: {
							show: false
						},
						boundaryGap: true,
						data: siteMap[xShowDisplayName].times
					},
					yAxis: {
						name: '水位值(m)',
						nameTextStyle: {
							fontSize: 14,
							color: 'white',
							padding: [0, 0, 10, 0]
						},
						type: 'value',
						min: 140,
						max: 190,
						splitNumber: 6,
						splitLine: {
							show: true,
							lineStyle: {
								color: 'rgba(255,255,255,.1)'
							}
						},
						axisLine: {
							show: false,
						},
						axisLabel: {
							show: true,
							margin: 20,
							textStyle: {
								fontSize: 14,
								color: 'white'
							}
						},
						axisTick: {
							show: true,
							length: 10,
							lineStyle: {
								color: 'white'
							}
						},
						minorTick: {
							show: true,
							splitNumber: 10,
							length: 10,
							lineStyle: {
								color: 'white'
							}
						}
					},
					// dataZoom: [{
					//     type: 'inside',
					//     start: 80,
					//     end: 100
					// }, {
					//     start: 80,
					//     end: 100
					// }],
					series: series
				})
				myChart.on('legendselectchanged', (e) => {
					series.forEach(it => {
						if(it.name == e.name) {
							it.label.show = true
						} else {
							it.label.show = false
						}
						myChart.setOption({
							series: series
						}, 0)
					})
				})
			}
		}
	}
</script>

<style scoped>

</style>